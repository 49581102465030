<template>
  <div class="flex md6 lg4">
    <va-card style="display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    -ms-flex-wrap:wrap;
        flex-wrap:wrap;">
      <va-image
        :src="p.ProfilePic"
        style="height: 400px;"
      />
      <va-card-title>{{ p.Username }}</va-card-title>

      <va-card-content style="margin-top: auto;">{{ p.Bio }}</va-card-content>
    </va-card>
  </div>
</template>

<script>
export default {
  props: {
    p: Object,
  },

  name: "DashboardCard"
}
</script>

<style scoped>

</style>
