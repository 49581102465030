<template>
  <div class="dashboard">
    <div class="row row-equal">
      <dashboard-card v-for="post in posts" :key="post" :p="post"></dashboard-card>
    </div>
  </div>
</template>

<script>
import DashboardCharts from './DashboardCharts'
import DashboardInfoBlock from './DashboardInfoBlock'
import DashboardTabs from './DashboardTabs'
import DashboardMap from './DashboardMap'
import DashboardCard from "@/pages/admin/dashboard/DashboardCard";
import axios from "axios";



export default {
  name: 'dashboard',
  posts: {},
  components: {
    DashboardCard
  },
  data(){
    return{
        posts: this.posts
    }
  },
  async created() {
    try {
      const response = (await axios.get(`${process.env.VUE_APP_SAREGH_HOST}/target`,{
        headers:{
          'Content-Type':'application/json',
          'token':'hellothisistoken',
        }
      }))
      this.posts = response.data.data
      console.log(this.posts)
    } catch (e) {
      this.errors.push(e)
    }
  },
  methods: {
    addAddressToMap({city, country}) {
      this.$refs.dashboardMap.addAddress({city: city.text, country})
    },
  },
}



</script>

<style lang="scss">
.row-equal .flex {
  .va-card {
    height: 100%;
  }
}

.dashboard {
  .va-card {
    margin-bottom: 0 !important;

    &__title {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
